import * as React from "react"
import SiteContainer from "../site-container";
import {Helmet} from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import dictionary from "../../dictionary";

const WorkflowsPage = ({pageContext: {recipe}}) => {
    return (
        <SiteContainer>
            <Helmet>
                <title>{recipe.name}</title>
                <meta name="description" content={recipe.description} />
                
            </Helmet>

            <nav className="bg-gray-200">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <ol className="breadcrumb breadcrumb-scroll">
                                <li className="breadcrumb-item">
                                    <a href="/workflows/" className="text-gray-700">
                                        Workflows
                                    </a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {recipe.name}
                                </li>
                            </ol>

                        </div>
                    </div>
                </div>
            </nav>
            <section className="py-8 py-md-11">
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <div className="row mb-6 mb-md-5">
                                <div className="col">
                                    <span className="badge rounded-pill bg-dark-soft mb-3">
                                        <span className="h6 text-uppercase">
                                            {recipe.total_steps} Steps
                                        </span>
                                    </span>
                                    <h2 className="fw-bold mb-0">
                                        {recipe.name}
                                    </h2>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={8}>
                            <div className="row mb-6 mb-md-8">
                                <div className="col">
                                    <ReactMarkdown
                                        children={recipe.content}
                                        remarkPlugins={[remarkGfm]}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} lg={4}>
                            <div className="card shadow-light-lg mb-5">
                                <div className="card-body">
                                    <div className="d-grid gap-2">
                                        <a href="/signup/" className="btn btn-primary btn-sm">
                                            Get Started for Free
                                        </a>
                                        <a href="/contact/" className="btn btn-outline-secondary me-1 btn-sm">
                                            Talk To Us
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-light py-8 py-md-11">
                <Container>
                    <Row className="justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8 text-center">
                            <h2>
                                Follow the <span className="text-primary">Recipe</span>
                            </h2>
                            <p className="fs-lg text-muted mb-7 mb-md-9 px-md-9">
                                Firstparty will guide you step by step.
                            </p>
                        </div>
                        <Col xs={12} lg={8}>
                            <div className="accordion shadow-light-lg mb-5 mb-md-6" id="helpAccordionOne">
                                <div className="accordion-item">
                                    <div className="accordion-button" role="button">
                                        <div className="me-auto">
                                            <h3 className="fw-bold mb-0">
                                                Workflow Steps
                                            </h3>
                                        </div>
                                        <span className="badge rounded-pill bg-success-soft ms-4">
                                            <span className="h6 text-uppercase">
                                                {recipe.total_steps} steps
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                {
                                    recipe.steps.map(function(step) {
                                        if(step.object !== "content") {
                                            return <div className="accordion-item" key={step.id}>
                                                <div className="accordion-button collapsed">
                                                    <div className="d-flex align-items-center">
                                                        <div className="me-5">
                                                            {React.createElement(dictionary[step.object].icon, { style: { display: 'flex' } })}
                                                        </div>
                                                        <div>
                                                            {step.title}
                                                            <div className="text-muted">
                                                        <span className="fs-sm me-4 d-none d-md-inline">
                                                            {step.subtitle}
                                                        </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        }
                                    })
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <div className="position-relative mt-n16 pb-10 pb-md-3">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-black">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="bg-black py-12">

            </section>
        </SiteContainer>
    )
}

export default WorkflowsPage
